import { SkipNavContent, SkipNavLink } from '@reach/skip-nav';
import '@reach/skip-nav/styles.css';
import React from 'react';
import { Helmet } from 'react-helmet';
import favicon from '../../static/favicon.ico';
import Navigation from '../components/Navigation';
import '../css-imports';
import useSiteMetadata from '../util/useSiteMetadata';


const HomePage = () => {
    const { title } = useSiteMetadata();
    return (<>
        <Helmet>
            <html lang="nb" />
            <title>{title}</title>
            <link rel="shortcut icon" href={favicon}></link>
            <meta name="theme-color" content="#002776"/>
            <meta name="color-scheme" content="light dark"/>
        </Helmet>
        <SkipNavLink>Hopp til innhold</SkipNavLink>
        <div className="sb1ds-wave-frontpage">
            <div className="sb1ds-layout">
                <Navigation />
                <main className="sb1ds-main sb1ds-main--frontpage">
                    <SkipNavContent />
                    <div className="sb1ds-intro sb1ds-intro--frontpage">
                        <h1 className="ffe-h1 sb1ds-intro__heading">Designsystemet</h1>
                        <p className="ffe-lead-paragraph sb1ds-intro__paragraph">
                            Felles retningslinjer og komponenter som hjelper oss med å lage helhetlige, brukervennlige, inkluderende løsninger for kundene våre
                        </p>
                        <div className="sb1ds-intro__illustration" role="presentation">
                            <img src="/img/collaboration.svg" alt="" aria-hidden="true" />
                        </div>
                    </div>

                    <div className="sb1ds-help">
                        <h2 className="ffe-h3">Bli med!</h2>
                        <p className="ffe-body-text">
                            Spørsmål om designsystemet, forslag til forbedringer eller noe annet på hjertet?
                        </p>
                        <ul className="ffe-bullet-list">
                            <li className="ffe-bullet-list__item">
                                <a className="ffe-link-text" href="https://github.com/SpareBank1/designsystem/issues">Legg inn et issue på GitHub</a>
                            </li>
                            <li className="ffe-bullet-list__item">
                                <a className="ffe-link-text" href="mailto:designsystem@sparebank1.no">Send oss en e-post</a>
                            </li>
                            <li className="ffe-bullet-list__item">
                                Si hei i <strong>#ext-designsystem</strong> på Slack
                            </li>
                        </ul>
                    </div>
                </main>
            </div>
        </div>
    </>);
};

export default HomePage;
